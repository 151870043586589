<template>
	<span class="text-warning">
		<template v-for="n in 5" :key="n">
			<span v-if="n<=ratingDF">
				<font-awesome-icon :icon="['fas', 'star']" v-if="(n<=rating)" />
				<font-awesome-icon :icon="['far', 'star-half-stroke']" v-else  />
			</span>
			<span v-else>
				<font-awesome-icon :icon="['far', 'star']" />
			</span>
		</template>
	</span>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const FontAwesomeIcon = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "fortawesome" */"@fortawesome/vue-fontawesome").then(mod => mod.FontAwesomeIcon),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default{
		props: ['rating'],
		components: {
			FontAwesomeIcon
		},

		computed:{
			ratingDF(){
				return Math.ceil(this.rating)
			}
		}
	}
</script>
