<template>
  <div class="container-fluid Quicksand">
    <div class="row" v-if="false">
      <div class="col-md-12 position-relative px-0 carousel-control-hidden" style=" height: 300px;">
        <img src="@/assets/image/pool.webp" alt="portada solicitante" class="position-absolute" style="left: 0px; right: 0px; top:0px; bottom: 0px; width: 100%; height: 100%; object-fit: cover;">
      </div>
    </div>
    <div class="w-50 mx-auto text-center">
      <img src="@/assets/image/auto-compras.webp" class="img-fluid">
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-8 p-4" style="font-size: 1.2rem">
        <p class="mb-3">
          Queremos ser parte de tus sueños y apoyarte con la mejor opción de crédito para la compra de tu carro seminuevo. En <span class="text-secondary font-weight-bold"><u>clubcashin.com</u></span> encuentra tu carro ideal o trae el que quieres comprar 
        </p>

        <div class="d-flex flex-column flex-md-row">
          <router-link :to="{name:'vehiculos.muro'}" class="btn btn-primary rounded-pill font-weight-bold mr-3 mb-3">
            Compra tu carro
          </router-link>
          <router-link :to="{name:'vehiculos.index'}" class="btn btn-secondary rounded-pill font-weight-bold mr-3 mb-3">
            ¿Por qué nosotros?
          </router-link>
        </div>
      </div>
    </div>

    <div class="row align-items-end justify-content-center bg-inversion">
      <div class="position-absolute d-none d-lg-block" style="left: 0px; z-index: 1; width: 10%;">
        <img src="@/assets/image/cash-in_isotipo_fondoazul.webp" class="img-fluid" alt="Club Cashin Isotipo" style="transform: rotate(180deg);">
      </div>
      <div class="col-md-11">
        <div class="row justify-content-center p-3 p-lg-5">
          <div class="col-lg-6 mb-3">
            <img src="@/assets/image/carro-compra-feliz.webp" class="img-fluid rounded">
          </div>
          <div class="col-lg-6 mb-3 font-weight-bolder">
            <p>Compra ahora tu carro seminuevo, con la opción de crédito ideal para tu bolsillo. Nos acomodamos a la cuota que quieras pagar. Queremos ser parte de tus sueños y apoyarte con la mejor opción de crédito para la compra de tu carro. Sabemos que es un esfuerzo grande y nuestra misión es hacerlo lo más sencillo y conveniente para ti!</p>

            <p>
              En <span class="font-weight-bold"><u>Clubcashin.com</u></span> queremos ofrecerte la mejor opción para tu compra de vehículo a través de un proceso 100% digital. Contamos con una gran variedad de carros en nuestra tienda en línea, si no está puedes traer el que quieras o lo encontramos por ti. Selecciona el carro que quieres comprar y solicita que <span class="font-weight-bold"><u>Clubcashin.com</u></span> certifique legal y mecánicamente tu nuevo vehículo.
            </p>

            <p>
              Nuestros préstamos son para cualquier tipo de vehículo
            </p>

            <ul class="my-2">
              <li>Sedan</li>
              <li>SUV para la familia</li>
              <li>Camioneta</li>
              <li>Panel</li>
              <li>Pickup</li>
              <li>Deportivo</li>
              <li>Camión</li>
              <li>Otros</li>
            </ul>

            <p>
              Solo preocúpate por el carro de tus sueños y nosotros nos encargaremos de darte las mejores condiciones con la mejor cuota de pago mensual. Todos nuestros préstamos cuentan con beneficio en seguro para su carro seminuevo, incluye servicio de grúa, dispositivo de ubicación GPS y asistencia en todo el país.  
            </p>

            <ol>
              <li class="mb-3">Tramita la solicitud de préstamo a través de nuestro formulario . Es fácil y rápido.</li>
              <li class="mb-3">Un experto de <span class="text-secodary font-weight-bold"><u>Clubcashin.com</u></span> procesará de forma rápida tu solicitud y buscará opciones que se adapten a lo que necesitas.</li>
              <li class="mb-3">Te daremos una respuesta en 24 horas con la propuesta ideal para ti.</li>
            </ol>

            <div class="text-center">
              <router-link :to="{name:'vehiculos.muro'}" class="btn btn-primary rounded-pill font-weight-bold mr-3">
                Compra tu carro
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="position-absolute text-right d-none d-lg-block" style="right: 0px; z-index: 1; width: 10%;">
        <img src="@/assets/image/cash-in_isotipo_fondoazul.webp" class="img-fluid" alt="Club Cashin Isotipo">
      </div>
    </div>

    <div class="row justify-content-center bg-light p-3 p-lg-5">
      <h2 class="mb-3 font-weight-bold">Te interesa vender tu carro? También te ayudamos!</h2>

      <div class="row justify-content-center">
        <div class="col-lg-6 mb-3">
          <img src="@/assets/image/carro-feliz.webp" class="img-fluid rounded">
        </div>
        <div class="col-lg-6 mb-3">
            <p>Publica tu carro en Clubcashin.com y véndelo, es muy sencillo y gratis.</p>

            <p>Los compradores casi siempre solicitan pagos a la hora de comprar un vehículo, <span class="text-secondary font-weight-bold"><u>Clubcashin.com</u></span> te soluciona ese problema permitiendote publicar gratis tu carro y si el cliente necesita crédito para comprarlo, nosotros se lo brindamos, mientras tu recibes tu dinero y te despreocupas.</p>

            <p>Par aumentar las oportunidades de venta nosotros le hacemos una inspección gratis a tu vehículo para confirmar que esté en buen estado y brindar confianza al comprador.</p>

            <p>Agenda la inspección de tu carro y en 24 horas estará listo para ser vendido.</p>

            <p class="font-weight-bold">¡NO TE COBRAMOS COMISIÓN DE VENTA!</p>

            <div class="text-center">
              <router-link :to="{name:'vehiculos.create'}" class="btn btn-secondary rounded-pill font-weight-bold mr-3">
                Publica tu carro
              </router-link>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>