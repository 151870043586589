<template>
    <div class="modal fade" id="terminos" data-backdrop="static" role="dialog" aria-labelledby="terminosLabel" aria-hidden="true">
		<div class="modal-dialog modal-xl modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header align-items-center">
					<div class="modal-title text-center w-100">
						<p class="mb-0"><strong>PRIVACIDAD DE DATOS</strong></p>
					</div>
					<button type="button" class="btn btn-link" data-dismiss="modal" aria-label="Close">
						<span class="material-icons">close</span>
					</button>
				</div>
				<div class="modal-body" id="modal-body">
                    <div class="terminos px-4">
                        <p style="text-align: justify;">Clubcashin.com <strong>(CUBE INVESTMENTS S.A.)</strong> con domicilio en: 3ª. Av. A 13-78 C. Lomas de pamplona, zona 13, Ciudad de Guatemala, Guatemala.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">De conformidad con la Protección de Datos Personales en Posesión de los Particulares, hace de su conocimiento que es responsable de recabar sus datos personales, del uso que se les dé a los mismos y de su protección. Este Aviso describe la forma en que recabamos y usamos los datos personales que usted nos brinda.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;"><strong><u>Tratamiento de sus Datos Personales:</u></strong></p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Recabamos sus datos personales para los efectos mencionados en el presente Aviso de Privacidad.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">En este sentido, hacemos de su conocimiento que sus datos personales serán tratados y resguardados con base en los principios de licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;"><strong><u>Finalidad en el tratamiento de sus datos personales:</u></strong></p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Recopilamos sus datos personales, por lo que solicitamos su consentimiento a través del presente Aviso de Privacidad con el objeto de utilizarlos para los siguientes fines:</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <ul style="text-align: justify;">
                            <li>Proveer los productos solicitados.</li>
                            <li>Proveer los servicios solicitados y/o contratados.</li>
                            <li>Responder a sus requerimientos de información, atención y servicio.</li>
                            <li>Evaluar la calidad del servicio que le brindamos.</li>
                            <li>Archivo de registros y expediente de la relación contractual para seguimiento de servicios futuros.</li>
                            <li>Gestión financiera, facturación y cobro.</li>
                            <li>Dar cumplimiento a las obligaciones y compromisos que hemos contraído con usted.</li>
                            <li>Adicionalmente, sus datos personales podrán ser utilizados para:</li>
                            <ul>
                                <li>Promoción y mercadeo de productos y servicios</li>
                                <li>Elaborar estudios y programas que son necesarios para determinar hábitos de uso y consumo.</li>
                                <li>Ofrecerle nuevos productos y servicios a la medida de sus intereses y necesidades.</li>
                                <li>Realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos.</li>
                                <li>Notificarle sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos.</li>
                                <li>Boletines y comunicados sobre nuestra organización.</li>
                                <li>Para las finalidades señaladas anteriormente podemos recolectar sus datos personales de distintas formas:</li>
                            </ul>
                        </ul>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <ol>
                            <li>Cuando visita nuestro sitio de Internet o utiliza nuestros servicios en línea.</li>
                            <li>Por teléfono.</li>
                            <li>Cuando obtenemos información a través de otras fuentes de acceso público que están permitidas por la ley (Directorios telefónicos o laborales, sitios web, etc.)</li>
                            <li>Datos personales que recolectamos:</li>
                            <li>Información de contacto (Nombre, Email, Dirección, Fecha de Nacimiento, Teléfono, Celular, Fax)</li>
                            <li>Información financiera y medios de pago (Tarjeta de crédito, débito, cheques)</li>
                            <li>Información Fiscal (RFC, Dirección de Facturación)</li>
                            <li>Identificación en Redes Sociales (Facebook, Twitter, LinkedIn, Google).</li>
                            <li>Medios para limitar el uso o divulgación de sus datos personales:</li>
                        </ol>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Hacemos de su conocimiento que sus datos personales serán resguardados bajo estrictas medidas de seguridad administrativas, técnicas y físicas las cuales han sido implementadas con el objeto de proteger sus datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;"><strong><u>Medios para el ejercicio de sus derechos como titular de los datos personales:</u></strong></p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;"><strong><u>Cambios al presente Aviso de Privacidad:</u></strong></p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">El presente Aviso de Privacidad podrá ser modificado en el futuro. En todo caso, cualquier modificación al mismo se hará de su conocimiento mediante el envío de un correo electrónico a la cuenta que usted nos proporcionó inicialmente para hacer de su conocimiento el presente Aviso de Privacidad y/o mediante la publicación del mismo en la siguiente página web: <a href="/">www.clubcashin.com</a></p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Le informamos que sus datos personales pueden ser transferidos y tratados dentro y fuera del País por personas distintas a <strong>CASH-IN</strong>, si nuestros servicios se realizan en un domicilio fuera de Guatemala.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">No seremos responsables en caso de que usted no reciba la referida notificación de cambio en el Aviso de Privacidad por causa de algún problema con su cuenta de correo electrónico o de transmisión de datos por internet.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Sin embargo, por su seguridad, el Aviso de Privacidad vigente estará disponible en todo momento en la página web antes señalada.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;"><strong>LIMITACIÓN DE USO Y DIVULGACIÓN DE INFORMACIÓN</strong></p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">En nuestro programa de notificación de promociones, ofertas y servicios a través de correo electrónico, sólo <a href="/">www.clubcashin.com</a> tiene acceso a la información recabada. Este tipo de publicidad se realiza mediante avisos y mensajes promocionales de correo electrónico, los cuales sólo serán enviados a usted y a aquellos contactos registrados para tal propósito, esta indicación podrá usted modificarla en cualquier momento enviando un correo a <a href="mailto:info@clubcashin.com">info@clubcashin.com</a></p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;"><strong>SOBRE EL USO DE COOKIES.</strong></p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Las cookies son pequeños archivos de texto que se guardan en la memoria de tu dispositivo, ellas se utilizan para guardar tus preferencias cuando te conectas a nuestro sitio, así como para rastrear determinados comportamientos o actividades llevadas a cabo por ti dentro de nuestro sitio. Las cookies pueden ser eliminadas e incluso bloquear su almacenamiento si así lo deseas, esto en las opciones de Preferencias o Configuración de tu navegador web <a href="/">www.clubcashin.com</a>, mediante el almacenamiento y uso de cookies, puede mejorar la experiencia del cliente, al recordar algunos aspectos del usuario lo cual ayuda a agilizar el tiempo de respuesta en nuestro sitio web.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Al continuar el usuario acepta haber leído y aceptado los términos y condiciones.</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                        <p style="text-align: justify;">Fecha de última actualización: Mayo 2023</p>
                        <p style="text-align: justify;"><strong>&nbsp;</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			window.$(this.$el).modal('show')
			var vm = this
			window.$(this.$el).on('hidden.bs.modal', function () {
				vm.toIndex()
			})
		},
		methods: {
			toIndex(){
				this.$router.back()
			},
		},
		beforeUnmount(){
			window.$('.modal-backdrop').remove()
		}
	}
</script>