<template>
  <div class="container-fluid Quicksand">
    <div class="row">
      <div class="col-md-12 position-relative px-0 carousel-control-hidden" style=" height: 300px;">
        <img src="@/assets/image/pool.webp" alt="portada solicitante" class="position-absolute" style="left: 0px; right: 0px; top:0px; bottom: 0px; width: 100%; height: 100%; object-fit: cover;">
      </div>
    </div>
    <div class="row justify-content-center">
      
      <div class="col-md-7 text-justify p-4" style="font-size: 1.2rem">
        <h2 class="text-center font-weight-bold mb-3 Aqum">Inversion colectiva (Crowdlending)</h2>
        <br>
        <br>
        <ul style="font-size: 1.2rem">
          <li class="font-weight-bold">
            Las inversiones colectivas en Clubcashin.com, son un nuevo modelo de inversión que permite a más de una persona participar en un mismo préstamo.
            <br>
            <br>
          </li>
          <li class="font-weight-bold">
            Por primera vez, puedes invertir como los inversionistas más sofisticados,
            diversificando tu portafolio en inversiones privadas.
            <br>
            <br>
          </li>
          <li class="font-weight-bold">
            Al invertir a traves del modelo de Crowdlending, pasas a formar parte
            porcentual en una misma inversión.
            <br>
            <br>
          </li>
          <li class="font-weight-bold">
            Clubcashin.com administra cada inversión en su totalidad, velando
            siempre por los intereses de ambas partes, manteniendo la transparencia
            y términos legales, mientras te relajas y recibes dividendos mensuales.
            <br>
            <br>
          </li>
          <li class="font-weight-bold">
            Esto te permite invertir montos menores, creando un portafolio diversificado
            de inversiones y así mitigar el riesgo.
            <br>
            <br>
          </li>
        </ul>
      </div>
    </div>
    <div class="">
      <div class="row">
        <div class="col-12 bg-inversion">
          <h2 class="text-center font-weight-bold py-5">Términos de uso</h2>
          <div class="row align-items-end justify-content-center">
            <div class="position-absolute d-none d-lg-block" style="left: 0px; z-index: 1; width: 23%;">
              <img src="@/assets/image/cash-in_isotipo_fondoazul.webp" class="img-fluid" alt="Club Cashin Isotipo" style="transform: rotate(180deg);">
            </div>
            <div class="col-md-7 pb-5">
              <ul class="pl-0">
                <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                  <h3 width="20px" class="font-weight-bold mb-0 ml-2">01</h3>
                  <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                  Las inversiones colectivas o Crowdlending aplica para montos solicitados mayores a Q30,000.00
                </li>
                <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                  <h3 width="20px" class="font-weight-bold mb-0 ml-2">02</h3>
                  <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                  Ticket mínimo: Q3,000.00 del monto total de la inversión en que participarás.
                </li>
                <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                  <h3 width="20px" class="font-weight-bold mb-0 ml-2">03</h3>
                  <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                  Puede un mismo inversionista optar a más del 10% o incluso, financiar el 100% si así lo quisiera.
                </li>
                <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                  <h3 width="20px" class="font-weight-bold mb-0 ml-2">04</h3>
                  <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                  Clubcashin.com conserva la administración total de la cartera para velar por la seguridad y liquidación total de cada inversionista participante.
                </li>
              </ul>
            </div>
            <div class="w-25 position-absolute text-right d-none d-lg-block" style="right: 0px; z-index: 1;">
              <img src="@/assets/image/cash-in_isotipo_fondoazul.webp" class="img-fluid" alt="Club Cashin Isotipo">
            </div>
          </div>
        </div>
      </div>
      <div class="row p-3 p-md-5 justify-content-center bg-white">
        <div class="col-md-12 text-center mb-3">
          <h3 class="font-weight-bold text-justify">
          Recuerda que todos los usuarios de Clubcashin.com han presentado sus requisitos, han superado todos los filtros de verificación y su capital es de procedencia lícita y siempre dentro del sistema bancario.
          </h3>
        </div>
        <div class="text-center mt-3">
          <router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
          ¡Empieza a Invertir!
          </router-link>
        </div>
      </div>
    </div>
    <div class="row bg-primary p-3 p-md-5">
      <div class="col-md-12 text-center text-white">
        <div class="text-center mb-3">
          <img src="@/assets/image/preguntas_inversionista.webp" class="img-fluid" style="width: 50px;" alt="Preguntas frecuentes">
        </div>
        <h2>Preguntas Frecuentes</h2>
      </div>
    </div>
    <div class="row bg-inversion p-md-5 p-3 justify-content-center">
      <div class="col-md-7 preguntas-carrousel">
        <div id="carouselPool" class="carousel slide col-md-12" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carouselPool" data-slide-to="0" class="active"></li>
            <li data-target="#carouselPool" data-slide-to="1"></li>
            <li data-target="#carouselPool" data-slide-to="2"></li>
            <li data-target="#carouselPool" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row align-items-stretch">
                <div class="col-md-6 mb-3">
                  <div class="d-flex">
                    <div>
                      <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                        01
                      </div>
                    </div>
                    <div class="font-weight-bold text-white text-left">
                      ¿Cómo saber si tu inversión es de riesgo controlado?
                    </div>
                  </div>
                  <hr class="border-light">
                  <p class="text-justify">
                    Tu inversión SIEMPRE estará garantizada por un activo (vehículo o bien inmueble) que vale al menos el doble del monto invertido y por otro lado clubcashin.com realizó un análisis tanto del bien como de el deudor y la capacidad de pago del mismo. Clubcashin.com será el administrador único del préstamo.
                  </p>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="d-flex">
                    <div>
                      <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                        02
                      </div>
                    </div>
                    <div class="font-weight-bold text-white text-left">
                      ¿Montos mínimos y máximos para invertir?
                    </div>
                  </div>
                  <hr class="border-light">
                  <ul class="text-justify">
                    <li>No existen mínimos o máximos media vez el activo puesto en garantía del crédito valga al menos el doble de lo solicitado. Independientemente de los montos solicitados los créditos pueden ser fondeados por más de un inversor en la figura de <strong>“POOL”</strong>. En esta figura todos en partes proporcionales serán acreedores en primer lugar de ese crédito específico. Clubcashin. com será el administrador único del préstamo.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-stretch">
                <div class="col-md-6 mb-3">
                  <div class="d-flex">
                    <div>
                      <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                        03
                      </div>
                    </div>
                    <div class="font-weight-bold text-white text-left">
                      ¿Qué retorno puedo esperar?
                    </div>
                  </div>
                  <hr class="border-light">
                  <ul class="text-justify">
                    <li>Tasas de retorno anual: desde 10% anual hasta el 14.11%.</li>
                  </ul>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="d-flex">
                    <div>
                      <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                        04
                      </div>
                    </div>
                    <div class="font-weight-bold text-white text-left">
                      Contamos con experiencia en el mercado financiero para ofrecer un soporte real y de riesgo controlado
                    </div>
                  </div>
                  <hr class="border-light">
                  <ul class="text-justify">
                    <li>Años de experiencia: + de 5 años de experiencia en créditos prendarios sobre vehículos</li>
                    <li>Record Histórico Club Cash-In: Pérdida de capital 0% histórico</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-stretch justify-content-center">
                <div class="col-md-6 mb-3">
                  <div class="d-flex">
                    <div>
                      <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                        05
                      </div>
                    </div>
                    <div class="font-weight-bold text-white text-left">
                      ¿Experiencia de Club Cash-In?
                    </div>
                  </div>
                  <hr class="border-light">
                  <ul class="text-justify">
                    <li>Club Cash-In es oficialmente la PRIMERA plataforma de créditos P2P (Peer to Peer)( de igual a igual) en el país, contando detrás de su estructura crediticia y de inversores con más de 20 años en el mercado de créditos prendarios sobre vehículos.</li>
                  </ul>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="d-flex">
                    <div>
                      <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                        06
                      </div>
                    </div>
                    <div class="font-weight-bold text-white text-left">
                      ¿Servicios ofrecidos por Club Cash-In?
                    </div>
                  </div>
                  <hr class="border-light">
                  <ul class="text-justify">
                    <li>Club Cash-In se encarga de llevar adelante desde la etapa inicial un crédito en nombre del solicitante de el mismo y de la parte inversora, iniciando por depurar a los diferentes solicitantes y sus bienes potencialmente puestos en garantía dentro de esta solicitud de financiamiento, haciendo un análisis exhaustivo de ambos tanto en las partes legales, fiscales, crediticias y valorando los bienes. Luego asesorando a ambas partes sobre como concretar el negocio de la mejor y más accesible manera, perfeccionando el mismo, llevando adelante la tarea de cobros y trámites legales durante el período que dure el crédito y si se llegará a una etapa de tener que entablar un proceso legal como sería una demanda o tomar posesión de un bien, Club Cash-In se encarga de lo mismo al 100% siempre velando de la manera más honesta y éticamente posible por ambas partes.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-stretch justify-content-center">
                <div class="col-md-6 mb-3">
                  <div class="d-flex">
                    <div>
                      <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                        07
                      </div>
                    </div>
                    <div class="font-weight-bold text-white text-left">
                      ¿Quiénes pueden invertir a través de Club Cash-In?
                    </div>
                  </div>
                  <hr class="border-light">
                  <ul class="text-justify">
                    <li>Puede hacerlo cualquier persona que desea invertir y poner a trabajar su dinero de manera inteligente con fondos de procedencia lícita y dentro de el sistema bancario. Mayor información al llenar documentación necesaria para realizar inversiones.</li>
                    <li>Cualquier persona que desee obtener altos rendimientos y que su dinero trabaje para ellos es bienvenida a pertenecer a el prestigioso Club de Inversionistas de la región “Club Cash-In”.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselPool" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselPool" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>