<template>
<div>
	<div class="container-fluid">
		<div class="row position-relative align-items-stretch">
			<div class="position-absolute bottom-0 left-0 right-0 text-center z-index-1">
				<img v-lazy="imageBanner" class="img-fluid" width="600" height="400" alt="Banner Cash In" v-if="imageBanner">
			</div>
			<div class="col-6 bg-inversion px-md-3 pr-lg-5">
				<div class="form-row justify-content-center h-100 py-inicio-padding">
					<div class="col-lg-8 d-flex flex-column pb-5">
						<div class="text-left text-md-center text-white font-weight-bold z-index-2">
							<h1>
								<router-link :to="{name:'opciones-inversion.index'}" class="text-white text-decoration-none">
									Empieza a <br class="d-block d-xl-none"> invertir
								</router-link>
							</h1>
						</div>
					</div>
				</div>
			</div>
			<div class="col-6 bg-credito px-md-3 pl-lg-5">
				<div class="form-row justify-content-center h-100 py-inicio-padding">
					<div class="col-lg-8 d-flex flex-column pb-5">
						<div class="text-right text-md-center text-white font-weight-bold z-index-2">
							<h1>
								<router-link :to="{name:'precalifique.index'}" class="text-white text-decoration-none">
									Solicita un <br class="d-block d-xl-none"> préstamo
								</router-link>
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div>
		<div class="container-lg">
			<div class="row justify-content-center mt-3">
				<div class="col-lg-12 text-center mb-3 p-0 p-lg-4">
					<h2 class="font-weight-bold display-4 d-inline-block">TIPOS DE PRÉSTAMOS</h2>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<PrestamosProductos />
		</div>
	</div>
	
	<div class="container-fluid">
		<div class="row justify-content-center bg-white">
			<div class="col-lg-10 col-xl-6 col-lg-8">
				<div class="card-body">
					<h2 class="text-left p-md-3">
					<span class="font-weight-bold">Clubcashin.com </span>
					<span class="font-weight-lighter">Conectamos a personas que buscan un préstamo para comprar un carro o garantizado por un carro, con personas que quieren prestar su dinero y generar un rendimiento.</span>
					</h2>
				</div>
			</div>
		</div>
	</div>

	<div class="container-fluid bg-white p-3">
		<div class="row justify-content-center align-items-center mb-3">
			<div class="col-lg-5 text-center">
				<h3 class="font-weight-bold text-center display-4 mb-3" v-if="isMobile">VENDE TU CARRO</h3>
				<img v-lazy="imageBannerHome" alt="Banner" class="img-fluid order-1 order-lg-0" width="600" height="400" v-if="imageBannerHome">
			</div>
			<div class="col-lg-4">
				<h3 class="font-weight-bold text-center display-4 mb-3" v-if="!isMobile">VENDE TU CARRO</h3>
				<p class="h3 font-weight-normal">Utiliza nuestro Marketplace gratis y recibe propuestas por tu carro. Si tu cliente necesita financiamiento nosotros se lo otorgamos.</p>
				<div class="text-center">
					<router-link :to="{name:'vehiculos.create'}" class="btn btn-secondary btn-lg px-4 rounded-pill font-weight-bold">
						Publicar
					</router-link>
				</div>
			</div>
		</div>
	</div>

	<div class="container-fluid bg-secondary p-3 p-lg-5">
		<div class="row justify-content-center align-items-center text-white" v-if="false">
			<div class="col-lg-10 col-lg-9 mt-3 mb-4">
				<h1 class="display-4 font-weight-bold text-center text-lg-left">Millones de quetzales otorgados en préstamos en toda Guatemala</h1>
			</div>
		</div>
		<BeneficiosPrestamos />
	</div>

	<div class="container">
		<div class="row justify-content-center mt-3 mb-5">
			<div class="col-lg-12">
				<h1 class="h1 font-weight-bold">Logros Clubcashin.com</h1>
			</div>
			<div class="col-lg-12 mt-3">
				<ul class="list-unstyled pl-2 pl-lg-5">
					<li class="d-flex mb-3">
						<div class="mr-1"><img v-lazy="imageCheck" alt="Check" width="30" height="30" v-if="imageCheck"></div>
						<p class="h3 text-justify mb-0">Certificaciones internacionales</p>
					</li>
					<li class="d-flex mb-3">
						<div class="mr-1"><img v-lazy="imageCheck" alt="Check" width="30" height="30" v-if="imageCheck"></div>
						<p class="h3 text-justify mb-0">Millones de quetzales en préstamos otorgados por cientos de inversionistas.</p>
					</li>
					<li class="d-flex mb-3">
						<div class="mr-1"><img v-lazy="imageCheck" alt="Check" width="30" height="30" v-if="imageCheck"></div>
						<p class="h3 text-justify mb-0">1ra. Plataforma P2P Lending en Guatemala</p>
					</li>
					<li class="d-flex mb-3">
						<div class="mr-1"><img v-lazy="imageCheck" alt="Check" width="30" height="30" v-if="imageCheck"></div>
						<p class="h3 text-justify mb-0">Miles de usuarios registrados</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
	
	<div class="bg-white">	
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-12 p-4">
					<h1 class="display-4 font-weight-bold mb-5">Marketplace AutoCompras</h1>

					<VehiculosCarouselCard />

					<router-link :to="{name:'vehiculos.muro'}" class="text-primary font-weight-bold h3">
						<u>
							Ver todos los carros
						</u>
						<img v-lazy="imageFlecha" alt="Flecha" width="20" height="11" v-if="imageFlecha">
					</router-link>
				</div>
			</div>
		</div>
	</div>

	<div class="container-fluid bg-primary">
		<div class="row justify-content-center py-5">
			<div class="col-lg-10">
				<Resenas />
			</div>
		</div>
	</div>
	<router-view />
</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const PrestamosProductos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PrestamosProductos" */"@/components/prestamos/Productos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BeneficiosPrestamos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PrestamosBeneficiosPrestamos" */"@/components/prestamos/BeneficiosPrestamos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	import VehiculosCarouselCard from "@/components/vehiculos/CarouselCard.vue"
	import Resenas from "@/components/Resenas.vue"

	export default {
		components: {
			BeneficiosPrestamos,
			PrestamosProductos,
			VehiculosCarouselCard,
			Resenas
		},
		data(){
			return {
				imageBanner: require('@/assets/image/banner.webp'),
				imageBannerHome: require('@/assets/image/banner_home.webp'),
				imageCheck: require('@/assets/image/check.webp'),
				imageFlecha: require('@/assets/image/flecha.webp')
			}
		},
		computed: {
			isMobile() {
				return this.$store.getters.isMobile
			}
		}
	}
</script>
