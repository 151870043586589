<template>
	<div class="carousel-white">
		<h1 class="text-white mb-3">Reseñas<span v-if="resena.rating>=3.5">(
			<span class="placeholder-glow" v-if="loadingResenas"><span class="placeholder col-2"></span></span>
			<Star :rating="resena.rating || 0" :title="resena.rating" v-else />
		)</span></h1>

		<Carousel :items-to-show="itemsReviews" :snapAlign="'start'" :wrap-around="true" :autoplay="5000" v-if="reviews.length>0">
			<Slide v-for="(review, index) in reviews" :key="index">
				<div class="border rounded h-100 w-100 p-3 mx-3 position-relative">
					<div class="position-absolute bg-primary p-2 google-review-image">
						<img v-lazy="imageGoogle" class="img-fluid" width="35" height="35" alt="Google">
					</div>

					<div class="text-white text-left h-100">
						<div v-if="loadingResenas" class="placeholder-glow">
							<div class="d-flex mb-auto">
								<div class="placeholder mr-3 col-1"></div>
								<div class="w-100">
									<span class="placeholder col-10"></span>
									<br>

									<span class="placeholder col-2"></span>
								</div>
							</div>

							<div>
								<span class="placeholder col-2 my-auto"></span>
							</div>

							<span class="placeholder col-7"></span>
							<span class="placeholder col-4"></span>
							<span class="placeholder col-4"></span>
							<span class="placeholder col-6"></span>
							<span class="placeholder col-8"></span>
						</div>
						<div class="d-flex flex-column" v-else>
							<div class="d-flex mb-auto">
								<div class="mr-3" v-if="review.profile_photo_url">
									<img v-lazy="review.profile_photo_url" width="50" height="50" :alt="review.author_name || 'Reseña'">
								</div>
								<div>
									<span class="font-weight-bold">
										{{review.author_name || ''}}
									</span>
									<br>

									<span>
										<small v-if="review.time">
											{{$filters.unixToDate(review.time)}}
										</small>
										<small v-else>
											{{review.tipo || ''}}
										</small>
									</span>
								</div>
							</div>

							<Star :rating="review.rating || 0" :class="{'mb-2':true, 'fa-2x text-center my-auto':!review.text}" :title="review.rating" />

							<div class="my-auto google-review-text" v-if="review.text">
								{{review.text || ''}}
							</div>
						</div>
					</div>
				</div>
			</Slide>

			<template #addons>
				<Pagination v-if="!isMobile" />
				<Navigation />
			</template>
		</Carousel>
		
		<a href="https://www.google.com/maps/place/Club+Cash+In/@14.6045752,-90.5443905,15z/data=!4m7!3m6!1s0x0:0xcc9c190a50d9f749!8m2!3d14.5992007!4d-90.5348714!9m1!1b1" target="_blank" class="text-white font-weight-bold h3">
			<u>Ver todas las reseñas</u>
			<img v-lazy="imageFlecha" class="img-white" alt="Flecha" width="20" height="11">
		</a>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const Carousel = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Carousel),
		loadingComponent: {template:`<loading-component />`}
	})

	const Navigation = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Navigation),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slide = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Slide),
		loadingComponent: {template:`<loading-component />`}
	})

	const Pagination = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Pagination),
		loadingComponent: {template:`<loading-component />`}
	})

	import Star from '@/components/Star.vue'
	
	export default {
		data(){
			return {
				loadingResenas: false,
				resena: {},
				reviews: [
					{
						rating: 0,
						profile_photo_url: '',
						author_name: '',
						time: '',
						tipo: '',
						text: ''
					},
					{
						rating: 0,
						profile_photo_url: '',
						author_name: '',
						time: '',
						tipo: '',
						text: ''
					},
					{
						rating: 0,
						profile_photo_url: '',
						author_name: '',
						time: '',
						tipo: '',
						text: ''
					},
					{
						rating: 0,
						profile_photo_url: '',
						author_name: '',
						time: '',
						tipo: '',
						text: ''
					},
				],
				imageGoogle: require('@/assets/image/google.svg'),
				imageFlecha: require('@/assets/image/flecha.webp')
			}
		},

		components:{
			Star,
			Carousel,
			Navigation,
			Slide,
			Pagination
		},

		mounted(){
			this.getReviews()
		},

		methods: {
			getReviews(){
				this.loadingResenas = true
				this.$store.dispatch('getReviews').then(res => {
					this.resena = ((res.data || {}).result || {})
					this.reviews = (this.resena.reviews || {}) || []
				}).catch(err => {
					return err
				}).then(() => {
					this.loadingResenas = false
				})
			},
		},

		computed: {
			isMobile() {
				return this.$store.getters.isMobile
			},

			itemsReviews(){
				return this.isMobile ? 1 : 2
			},
		}
	}
</script>