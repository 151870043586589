<template>
	<div>
		<Carousel :items-to-show="itemsVehiculos" :wrap-around="true" :autoplay="5000" v-if="vehiculos.length>0">
			<Slide v-for="(vehiculo, index) in vehiculosFilter" :key="index">
				<router-link class="card rounded-sm cursor-pointer text-decoration-none w-100 h-100 px-2" :to="{name:'vehiculos.show', params:{id:vehiculo.idVehiculo}}">
					<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculo.estatus!=1}">
						<img v-lazy="$filters.storageMin(vehiculo.ruta)" class="w-100 rounded-top object-fit-cover" :alt="vehiculo.marca" width="250" height="160" v-if="vehiculo.ruta">
						<div v-if="vehiculo.idOferta!=null" class="badge badge-light position-absolute bottom-10 left-10" title="Haz realizado una oferta por este vehículo">
							Oferta realizada
						</div>
						<div v-if="vehiculo.estatus==1" class="badge badge-inversion position-absolute top-10 right-10">
							Disponible
						</div>
						<div v-if="vehiculo.estatus==2" class="badge badge-gray position-absolute top-10 right-10">
							Apartado
						</div>
						<div v-if="vehiculo.estatus==3" class="badge badge-danger position-absolute top-10 right-10">
							Vendido
						</div>

						<div class="badge badge-light position-absolute bottom-10 right-10">
							{{$filters.currency(vehiculo.precio)}}
						</div>
					</div>
					<div class="rounded-bottom bg-primary text-white py-2 px-3">
						<div class="text-truncate ">
							<span class="font-weight-bold">{{vehiculo.marca}} {{vehiculo.modelo}}</span>
						</div>
						<div class="d-flex align-items-center justify-content-between">
							<div class="font-small align-items-center d-flex">
								<span class="material-icons align-self-center mr-1">event</span> {{vehiculo.ano}}
							</div>
							<div class="font-small align-items-center d-flex">
								<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculo.numeroPasajeros}}
							</div>
							<div class="font-small align-items-center d-flex justify-content-end">
								{{vehiculo.desTransmision}}
							</div>
						</div>
					</div>
				</router-link>
			</Slide>

			<template #addons>
				<Pagination v-if="!isMobile" />
				<Navigation />
			</template>
		</Carousel>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const Carousel = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Carousel),
		loadingComponent: {template:`<loading-component />`}
	})

	const Navigation = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Navigation),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slide = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Slide),
		loadingComponent: {template:`<loading-component />`}
	})

	const Pagination = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Pagination),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			Carousel,
			Slide,
			Navigation,
			Pagination
		},

		data(){
			return {
				loadingVehiculos: false,
				vehiculos: []
			}
		},

		mounted(){
			this.getVehiculosDisponibles()
		},

		methods: {
			getVehiculosDisponibles(){
				this.loadingVehiculos = true
				this.$store.dispatch('getVehiculosDisponibles', {
					idUsuarioVehiculo: this.user.idusuario
				}).then(response => {
					this.vehiculos = response.data || []
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingVehiculos = false
				})
			},
		},

		computed: {
			vehiculosFilter(){
				return this.vehiculos.slice(0, 20)
			},
			itemsVehiculos(){
				return this.isMobile ? 1 : 4
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			isMobile() {
				return this.$store.getters.isMobile
			}
		}
	}
</script>