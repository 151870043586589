<template>
  <div>
    <div class="bg-white">
      <div class="container-fluid bg-white">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-8 mb-3 mb-lg-5 order-1 order-lg-0">
            <h1 class="display-4 text-center mt-lg-5 font-weight-bold">
              INVERSIONES <br> PEER TO PEER
            </h1>
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <p class="h1 mt-3 mt-lg-5" style="font-weight: lighter;">
                  <span class="font-weight-bold">Inversiones</span> de persona a persona con buenos rendimientos
                </p>
              </div>
            </div>
            
            <div class="text-center mt-3 mt-lg-5">
              <router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
                ¡Empieza a Invertir!
              </router-link>
            </div>
          </div>
          <div :class="{'col-lg-4 mb-3 mb-lg-5 p-0 order-0 order-lg-1':true, 'text-center':isMobile, 'text-right':!isMobile}">
            <img src="@/assets/image/banner_inversionista.webp" class="img-fluid" alt="Préstamo">
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 mt-5 mb-lg-5">
            <div class="row">
              <div class="col-lg-12 mb-5 mb-lg-3">
                <div class="row">
                  <div class="col-lg-6">
                    <h2 class="mb-2 mb-lg-4 font-weight-bold">¿Porqué es mi mejor opción?</h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="accordion" id="accordionExample">
                      <div class="" id="headingOne">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <span class="h3 mb-0 text-primary">Beneficios</span>
                            <img src="@/assets/image/down-arrow.webp">
                          </button>
                        </h2>
                      </div>

                      <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body">
                          <div class="pr-md-5">
                            <ol class="py-3">
                              <li class="mb-2">
                                <strong>Buenos rendimientos:</strong> es muy rentable, puedes obtener retornos de hasta 14.11% anual.
                              </li>
                              <li class="mb-2">
                                <strong>Accesible:</strong> hay inversiones para todos.
                              </li>
                              <li class="mb-2">
                                <strong>Transparente:</strong> sabes exactamente el destino, plazo, retorno y riesgo de tu inversión.
                              </li>
                              <li class="mb-2">
                                <strong>Sencillo:</strong> todo es online, tú inviertes y clubcashin.com se encarga del resto.
                              </li>
                              <li class="mb-2">
                                <strong>Seguro:</strong> tu inversión siempre estará garantizada por un activo real que vale al menos el doble del monto invertido.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mb-3">
                <div class="row">
                  <div class="col-lg-6">
                    <h2 class="mb-2 mb-lg-4 font-weight-bold">¿Cómo funciona?</h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="accordion" id="accordionExample">
                      <div class="" id="headingTwo">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <span class="h3 mb-0 text-primary">Proceso</span>
                            <img src="@/assets/image/down-arrow.webp" alt="">
                          </button>
                        </h2>
                      </div>

                      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div class="card-body">
                          <div class="pr-md-5">
                              <ol>
                                <li class="mb-2">Regístrate con tu correo electrónico y llena tu perfil</li>
                                <li class="mb-2">Completa tus requisitos</li>
                                <li class="mb-2">Elige en qué préstamo deseas invertir</li>
                                <li class="mb-2">Haz tu inversión</li>
                                <li class="mb-2">Recibe mensualmente en tu cuenta el rendimiento de tu inversión</li>
                                <li class="mb-2">Vuelve a invertir tus ganancias para hacer crecer aún más tu dinero</li>
                              </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 mb-3">
                <div class="row">
                  <div class="col-lg-6">
                    <h2 class="mb-2 mb-lg-4 font-weight-bold">¿Qué necesitas para invertir?</h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="accordion" id="accordionExample">
                      <div class="" id="headingThree">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <span class="h3 mb-0 text-primary">Requisitos</span>
                            <img src="@/assets/image/down-arrow.webp" alt="">
                          </button>
                        </h2>
                      </div>

                      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div class="card-body">
                          <div class="pr-md-5">
                              <ul>
                                <li class="mb-2">DPI vigente</li>
                                <li class="mb-2">Recibo de agua, luz ó teléfono fijo</li>
                                <li class="mb-2">Fondos a invertirse deben estar bancarizados</li>
                              </ul>

                              <p class="font-weight-bold">Si el carro que deseas no esta en nuestra plataforma</p>

                              <ul>
                                <li class="mb-2">Copia de nombramiento del representante legal</li>
                                <li class="mb-2">DPI del representante legal</li>
                                <li class="mb-2">Copia escritura constitución sociedad</li>
                                <li class="mb-2">Copia de patentes de comercio y sociedad</li>
                                <li class="mb-2">Copia de RTU de la empresa</li>
                              </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid bg-inversion">
        <div class="px-3 pt-5 pb-3">
          <h2 class="text-center text-white">Preguntas Frecuentes</h2>
        </div>

        <div class="row px-3 pt-5 pb-3 justify-content-center">
          <div class="col-md-7 preguntas-carrousel">
            <div id="carouselPreguntasFrecuentes" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselPreguntasFrecuentes" data-slide-to="0" class="active"></li>
                <li data-target="#carouselPreguntasFrecuentes" data-slide-to="1"></li>
                <li data-target="#carouselPreguntasFrecuentes" data-slide-to="2"></li>
                <li data-target="#carouselPreguntasFrecuentes" data-slide-to="3"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row align-items-stretch">
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div class="font-weight-bold text-white text-left">
                          ¿Cómo saber si tu inversión es de riesgo controlado?
                        </div>
                      </div>
                      <hr class="border-light">
                      <p class="text-justify text-white">
                        Tu inversión SIEMPRE estará garantizada por un activo (vehículo o bien inmueble) que vale al menos el doble del monto invertido y por otro lado clubcashin.com realizó un análisis tanto del bien como de el deudor y la capacidad de pago del mismo. Clubcashin.com será el administrador único del préstamo.
                      </p>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div class="font-weight-bold text-white text-left">
                          ¿Montos mínimos y máximos<br> para invertir?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify text-white">
                        <li>No existen mínimos o máximos media vez el activo puesto en garantía del crédito valga al menos el doble de lo solicitado. Independientemente de los montos solicitados los créditos pueden ser fondeados por más de un inversor en la figura de <strong>“POOL”</strong>. En esta figura todos en partes proporcionales serán acreedores en primer lugar de ese crédito específico. Clubcashin. com será el administrador único del préstamo.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row align-items-stretch">
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div class="font-weight-bold text-white text-left">
                          ¿Qué retorno puedo esperar?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify text-white">
                        <li>Tasas de retorno anual: desde 10% anual (histórico marca ingresos hasta de 58.80% anual en algunos casos dependiendo de el tipo de crédito realizado).</li>
                      </ul>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div class="font-weight-bold text-white text-left">
                          Contamos con experiencia en el mercado financiero para ofrecer un soporte real y de riesgo controlado
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify text-white">
                        <li>Años de experiencia: + de 5 años de experiencia en créditos prendarios sobre vehículos</li>
                        <li>Record Histórico Club Cash-In: Pérdida de capital 0% histórico</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row align-items-stretch justify-content-center">
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div class="font-weight-bold text-white text-left">
                          ¿Experiencia de Club Cash-In?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify text-white">
                        <li>Club Cash-In es oficialmente la PRIMERA plataforma de créditos P2P (Peer to Peer)( de igual a igual) en el país, contando detrás de su estructura crediticia y de inversores con más de 20 años en el mercado de créditos prendarios sobre vehículos. </li>
                      </ul>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div class="font-weight-bold text-white text-left">
                          ¿Servicios ofrecidos por Club Cash-In?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify text-white">
                        <li>Club Cash-In se encarga de llevar adelante desde la etapa inicial un crédito en nombre del solicitante de el mismo y de la parte inversora, iniciando por depurar a los diferentes solicitantes y sus bienes potencialmente puestos en garantía dentro de esta solicitud de financiamiento, haciendo un análisis exhaustivo de ambos tanto en las partes legales, fiscales, crediticias y valorando los bienes. Luego asesorando a ambas partes sobre como concretar el negocio de la mejor y más accesible manera, perfeccionando el mismo, llevando adelante la tarea de cobros y trámites legales durante el período que dure el crédito y si se llegará a una etapa de tener que entablar un proceso legal como sería una demanda o tomar posesión de un bien, Club Cash-In se encarga de lo mismo al 100% siempre velando de la manera más honesta y éticamente posible por ambas partes.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row align-items-stretch justify-content-center">
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div class="font-weight-bold text-white text-left">
                          ¿Quiénes pueden invertir a través de Club Cash-In?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify text-white">
                        <li>Puede hacerlo cualquier persona que desea invertir y poner a trabajar su dinero de manera inteligente con fondos de procedencia lícita y dentro de el sistema bancario. Mayor información al llenar documentación necesaria para realizar inversiones.</li>
                        <li>Cualquier persona que desee obtener altos rendimientos y que su dinero trabaje para ellos es bienvenida a pertenecer a el prestigioso Club de Inversionistas de la región “Club Cash-In”.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselPreguntasFrecuentes" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselPreguntasFrecuentes" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">        
        <div class="px-3 py-5">
          <h2 class="text-center font-weight-bold">
            Las historias de nuestros inversionistas
          </h2>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-10 preguntas-carrousel beneficios-carrousel">
            <div id="carousel" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carousel" data-slide-to="0" class="active"></li>
                <li data-target="#carousel" data-slide-to="1"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="form-row align-items-stretch">
                    <div class="col-md-6 mb-3 px-3">
                      <div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
                        <div class="position-absolute p-2" style="right: -20px; top: -2px; background-color: #f7f7f7;">
                          <img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 50px;">
                        </div>
                        <div class="card-body pr-md-5" style="font-weight: 500;">
                          <h5 class="card-title text-primary font-weight-bold">
                          Hans, 80 años - Inversionista
                          </h5>
                          <p class="text-justify">
                            Pensé que iba a ser más difícil pero resultó bastante sencillo, invertí mis ahorros en dos créditos prendarios y ahora tengo 4 inversiones. Obtuve un rendimiento de 24% anual.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3 px-3">
                      <div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
                        <div class="position-absolute p-2" style="right: -20px; top: -2px; background-color: #f7f7f7;">
                          <img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 50px;">
                        </div>
                        <div class="card-body pr-md-5" style="font-weight: 500;">
                          <h5 class="card-title text-primary font-weight-bold">
                          Diego, 33 años - Inversionista
                          </h5>
                          <p class="text-justify">
                            La empresa familiar contaba con liquidez, nos presentaron la opción de invertir en este modelo. Anualmente genero de 5 a 6 veces lo que generaba en la cuenta de ahorros de la empresa.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="form-row align-items-stretch justify-content-center">
                    <div class="col-md-6 mb-3 px-3">
                      <div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
                        <div class="position-absolute p-2" style="right: -20px; top: -2px; background-color: #f7f7f7;">
                          <img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 50px;">
                        </div>
                        <div class="card-body pr-md-5" style="font-weight: 500;">
                          <h5 class="card-title text-primary font-weight-bold">
                          Carlos, 35 años - Inversionista
                          </h5>
                          <p class="text-justify">
                            Una platafroma muy amigable y sencilla para el inversionista y cliente. Reconozco el apoyo y respuesta que me han brindado en gestiones solicitadas. Mi portafolio ya es de tres inversiones prendarias con retorno arriba del 36% anual.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>  

    <div class="container-fluid" v-if="false">
      <div class="row">
        <div class="col-md-12 position-relative px-0 carousel-control-hidden" style=" height: 300px;">
          <img src="@/assets/image/portada-inversor.webp" alt="portada solicitante" style="left: 0px; right: 0px; top:0px; bottom: 0px; width: 100%; height: 100%; object-fit: cover;" class="position-absolute">
          <div id="carouselExampleCaptions" class="carousel slide h-100 position-relative" data-ride="carousel" style="background-color: rgba(0,0,0,.31)">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner h-100">
              <div class="carousel-item h-100 active">
                <div class="carousel-caption d-flex align-items-center h-100 text-left" style="left: 0px; right: 0px;">
                  <h1 class="p-3 p-md-5 text-white">
                    ¿Cuál es el mejor momento para empezar a invertir? <br><strong>HOY, el otro mejor momento fue AYER.</strong>
                  </h1>
                </div>
              </div>
              <div class="carousel-item h-100">
                <div class="carousel-caption d-flex align-items-center h-100 text-left" style="left: 0px; right: 0px;">
                  <h1 class="p-3 p-md-5 text-white">
                    Nunca dependa de un ingreso único, haga una inversión para crear una segunda fuente <strong>“Warren Buffet”</strong>
                  </h1>
                </div>
              </div>
              <div class="carousel-item h-100">
                <div class="carousel-caption d-flex align-items-center h-100 text-left" style="left: 0px; right: 0px;">
                  <h1 class="p-3 p-md-5 text-white"  >
                    P2P es un modelo disruptivo para generar dinero sin dedicarle tiempo o esfuerzo, <strong>clubcashin.com</strong> hace todo el trabajo.
                  </h1>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row bg-inversion justify-content-center position-relative">
        <div class="position-absolute" style="top: -60px; left: 0px; right: 0px; z-index: 1; text-align: center;">
          <img src="@/assets/image/icon_container.webp" class="img-fluid" style="height: 120px;">
        </div>
        <div class="col-md-10 col-lg-6 text-center">
          <div class="p-3 py-5 p-md-5">
            <h2 class="my-5 font-weight-normal" style="line-height: 1.6">
              <strong>Invierte</strong> tu dinero en préstamos que estarán respaldados por activos y genera mejores rendimientos.
            </h2>
            <router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
              ¡Empieza a Invertir!
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="!isMobile">
        <div class="row">
          <div class="col-12 bg-primary p-3 p-md-5">
            <h1 class="text-center text-white mb-3">Beneficios</h1>
            <div class="row justify-content-center">
              <div class="col-md-12 col-10">
                <div class="row justify-content-center">
                  <div class="col-md-3 mb-3 mb-md-0 p-0 p-0 position-relative d-flex align-items-center justify-content-center">
                    <img src="@/assets/image/beneficio_inversion_1.webp" class="img-fluid" alt="Escoge la mejor tasa">
                    <div class="position-absolute h-100 d-flex align-items-center">
                      <div class="w-50 mx-auto">
                        <div class="form-row align-items-center">
                          <div class="col-1 position-absolute" style="left: 10px;">
                            <h1 class="text-white font-weight-bold">01</h1>
                          </div>
                          <div class="col-12 text-center font-lg">
                            <strong>Buenos rendimientos:</strong> es muy rentable, puedes obtener retornos de hasta 14.11% anual.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 mb-md-0 p-0">
                    <img src="@/assets/image/flechas_verdes.webp" class="img-fluid mx-auto" style="width: 18px;" alt="Flecha">
                  </div>
                  <div class="col-md-3 mb-3 mb-md-0 p-0 p-0 position-relative d-flex align-items-center justify-content-center">
                    <img src="@/assets/image/beneficio_inversion_2.webp" class="img-fluid" alt="Escoge el plazo">
                    <div class="position-absolute h-100 d-flex align-items-center">
                      <div class="w-50 mx-auto">
                        <div class="form-row align-items-center">
                          <div class="col-1 position-absolute" style="left: 10px;">
                            <h1 class="text-white font-weight-bold">03</h1>
                          </div>
                          <div class="col-12 text-center font-lg">
                            <strong>Transparente:</strong>
                            sabes exactamente el destino, plazo, retorno y riesgo de tu inversión.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 mb-md-0 p-0">
                    <img src="@/assets/image/flechas_verdes.webp" class="img-fluid mx-auto" style="width: 18px;" alt="Flecha">
                  </div>
                  <div class="col-md-3 mb-3 mb-md-0 p-0 p-0 position-relative d-flex align-items-center justify-content-center">
                    <img src="@/assets/image/beneficio_inversion_3.webp" class="img-fluid" alt="Es sencillo">
                    <div class="position-absolute h-100 d-flex align-items-center">
                      <div class="w-50 mx-auto">
                        <div class="form-row align-items-center">
                          <div class="col-1 position-absolute" style="left: 10px;">
                            <h1 class="text-white font-weight-bold">05</h1>
                          </div>
                          <div class="col-12 text-center font-lg">
                            <strong>Seguro:</strong> tu inversión siempre estará garantizada por un activo real que vale al menos el doble del monto invertido.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center align-items-end spacing-beneficio" style="margin-top: -131px;">
                  <div class="col-md-3 mb-3 mb-md-0 p-0 p-0 position-relative d-flex align-items-center justify-content-center">
                    <img src="@/assets/image/beneficio_4.webp" class="img-fluid" alt="Cuotas fijas">
                    <div class="position-absolute h-100 d-flex align-items-center">
                      <div class="w-50 mx-auto">
                        <div class="form-row align-items-center">
                          <div class="col-1 position-absolute" style="left: 10px;">
                            <h1 class="text-inversion font-weight-bold">02</h1>
                          </div>
                          <div class="col-12 text-center font-lg">
                            <strong>Accesible:</strong> hay inversiones para todos.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 mb-md-0 p-0">
                    <img src="@/assets/image/flechas_verdes.webp" class="img-fluid" style="width: 18px;" alt="Flechas">
                  </div>
                  <div class="col-md-3 mb-3 mb-md-0 p-0 p-0 position-relative d-flex align-items-center justify-content-center">
                    <img src="@/assets/image/beneficio_5.webp" class="img-fluid" alt="Sin fiador">
                    <div class="position-absolute h-100 d-flex align-items-center">
                      <div class="w-50 mx-auto">
                        <div class="form-row align-items-center">
                          <div class="col-1 position-absolute" style="left: 10px;">
                            <h1 class="text-inversion font-weight-bold">04</h1>
                          </div>
                          <div class="col-12 text-center font-lg">
                            <strong>Sencillo:</strong> todo es online, tú inviertes y clubcashin.com se encarga del resto.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 bg-inversion">
            <h2 class="text-center font-weight-bold py-5">¿Cómo funciona?</h2>
            <div class="row align-items-end justify-content-center">
              <div class="position-absolute" style="left: 0px; z-index: 1; width: 23%;">
                <img src="@/assets/image/solicitante.webp" class="img-fluid" alt="Hombre solicitante">
              </div>
              <div class="col-md-7 pb-5">
                <ul class="pl-0">
                  <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                    <h3 width="20px" class="font-weight-bold mb-0 ml-2">01</h3>
                    <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                    Regístrate con tu correo electrónico y llena tu perfil
                  </li>
                  <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                    <h3 width="20px" class="font-weight-bold mb-0 ml-2">02</h3>
                    <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                    Completa tus requisitos
                  </li>
                  <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                    <h3 width="20px" class="font-weight-bold mb-0 ml-2">03</h3>
                    <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                    Elige en qué préstamo deseas invertir
                  </li>
                  <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                    <h3 width="20px" class="font-weight-bold mb-0 ml-2">04</h3>
                    <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                    Haz tu inversión
                  </li>
                  <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                    <h3 width="20px" class="font-weight-bold mb-0 ml-2">05</h3>
                    <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                    Recibe mensualmente en tu cuenta el rendimiento de tu inversión
                  </li>
                  <li class="d-flex align-items-center bg-light rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
                    <h3 width="20px" class="font-weight-bold mb-0 ml-2">06</h3>
                    <div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
                    Vuelve a invertir tus ganancias para hacer crecer aún más tu dinero
                  </li>
                </ul>
              </div>
              <div class="w-25 position-absolute" style="right: 0px; z-index: 1;">
                <img src="@/assets/image/solicitante2.webp" class="img-fluid" alt="Mujer solicitante">
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center position-relative p-3 p-md-5">
          <div class="position-absolute bg-inversion" style="bottom: 0px; left: 0px; right: 0px; top: 50%; display: block;"></div>
          <div class="col-md-8">
            <div class="row justify-content-center">
              <div class="col-10 col-md-4 d-flex flex-column justify-content-center mb-3">
                <div class="overflow-hidden rounded-sm mb-3">
                  <img src="@/assets/image/inversion.webp" class="img-fluid" alt="Inversión">
                </div>
                <br>
                <div class="text-center mb-3">
                  <img src="@/assets/image/flechas_verdes.webp" class="img-fluid d-inline-block d-sm-none" style="width: 20px;" alt="Flecha">
                  <img src="@/assets/image/arrow_down_white.webp" class="img-fluid d-none d-sm-inline-block" style="width: 20px;" alt="Flecha">
                </div>
                <div class="text-center mb-3">
                  <img src="@/assets/image/mano.webp" class="img-fluid" style="width: 75px;" alt="Inversionista">
                </div>
                <br>
                <div class="text-center">
                  <router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
                    ¡Empieza a Invertir!
                  </router-link>
                </div>
              </div>
              <div class="col-md-8 mb-3">
                <div class="card overflow-hidden rounded-sm">
                  <div class="card-header p-2 bg-inversion">
                    <h5 class="card-title mb-0 font-weight-bold mx-3">Requisitos</h5>
                  </div>
                  <div class="card-body bg-light">
                    <div>
                      <ul>
                        <li>DPI vigente</li>
                        <li>Recibo de agua, luz ó teléfono fijo</li>
                        <li>Fondos a invertirse deben estar bancarizados</li>
                      </ul>
                      <p>
                        <strong>
                        Si eres una persona jurídica (todo lo anterior) y adicional a:
                        </strong>
                      </p>
                      <ul>
                        <li>Copia de nombramiento del representante legal</li>
                        <li>DPI del representante legal</li>
                        <li>Copia escritura constitución sociedad</li>
                        <li>Copia de patentes de comercio y sociedad</li>
                        <li>Copia de RTU de la empresa</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-inversion justify-content-center">
          <div class="col-md-6 p-3 p-md-5 text-center text-primary">
            <h2 class="mb-3">Las <strong>historias</strong> de <br>
            nuestros <strong class="text-primary">clientes</strong></h2>
            <div class="text-center">
              <img src="@/assets/image/arrow_down_white.webp" class="img-fluid" style="width: 20px;">
            </div>
          </div>
        </div>
        <div class="row justify-content-center py-5">
          <div class="col-lg-6 col-md-10 preguntas-carrousel beneficios-carrousel">
            <div id="carousel" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carousel" data-slide-to="0" class="active"></li>
                <li data-target="#carousel" data-slide-to="1"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="form-row align-items-stretch">
                    <div class="col-md-6 mb-3 px-3">
                      <div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
                        <div class="position-absolute p-2" style="right: -20px; top: -2px; background-color: #f7f7f7;">
                          <img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 50px;">
                        </div>
                        <div class="card-body pr-md-5" style="font-weight: 500;">
                          <h5 class="card-title text-primary font-weight-bold">
                          Hans, 80 años - Inversionista
                          </h5>
                          <p class="text-justify">
                            Pensé que iba a ser más difícil pero resultó bastante sencillo, invertí mis ahorros en dos créditos prendarios y ahora tengo 4 inversiones. Obtuve un rendimiento de 24% anual.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3 px-3">
                      <div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
                        <div class="position-absolute p-2" style="right: -20px; top: -2px; background-color: #f7f7f7;">
                          <img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 50px;">
                        </div>
                        <div class="card-body pr-md-5" style="font-weight: 500;">
                          <h5 class="card-title text-primary font-weight-bold">
                          Diego, 33 años - Inversionista
                          </h5>
                          <p class="text-justify">
                            La empresa familiar contaba con liquidez, nos presentaron la opción de invertir en este modelo. Anualmente genero de 5 a 6 veces lo que generaba en la cuenta de ahorros de la empresa.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="form-row align-items-stretch justify-content-center">
                    <div class="col-md-6 mb-3 px-3">
                      <div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
                        <div class="position-absolute p-2" style="right: -20px; top: -2px; background-color: #f7f7f7;">
                          <img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 50px;">
                        </div>
                        <div class="card-body pr-md-5" style="font-weight: 500;">
                          <h5 class="card-title text-primary font-weight-bold">
                          Carlos, 35 años - Inversionista
                          </h5>
                          <p class="text-justify">
                            Una platafroma muy amigable y sencilla para el inversionista y cliente. Reconozco el apoyo y respuesta que me han brindado en gestiones solicitadas. Mi portafolio ya es de tres inversiones prendarias con retorno arriba del 36% anual.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        <div class="row bg-primary p-3 p-md-5">
          <div class="col-md-12 text-center text-white">
            <div class="text-center mb-3">
              <img src="@/assets/image/preguntas_inversionista.webp" class="img-fluid" style="width: 50px;" alt="Preguntas frecuentes">
            </div>
            <h2>Preguntas Frecuentes</h2>
          </div>
        </div>
        <div class="row bg-inversion p-md-5 p-3 justify-content-center">
          <div class="col-md-7 preguntas-carrousel">
            <div id="carouselPreguntasFrecuentes" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselPreguntasFrecuentes" data-slide-to="0" class="active"></li>
                <li data-target="#carouselPreguntasFrecuentes" data-slide-to="1"></li>
                <li data-target="#carouselPreguntasFrecuentes" data-slide-to="2"></li>
                <li data-target="#carouselPreguntasFrecuentes" data-slide-to="3"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row align-items-stretch">
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div>
                          <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                            01
                          </div>
                        </div>
                        <div class="font-weight-bold text-white text-left">
                          ¿Cómo saber si tu inversión es de riesgo controlado?
                        </div>
                      </div>
                      <hr class="border-light">
                      <p class="text-justify">
                        Tu inversión SIEMPRE estará garantizada por un activo (vehículo o bien inmueble) que vale al menos el doble del monto invertido y por otro lado clubcashin.com realizó un análisis tanto del bien como de el deudor y la capacidad de pago del mismo. Clubcashin.com será el administrador único del préstamo.
                      </p>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div>
                          <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                            02
                          </div>
                        </div>
                        <div class="font-weight-bold text-white text-left">
                          ¿Montos mínimos y máximos para invertir?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify">
                        <li>No existen mínimos o máximos media vez el activo puesto en garantía del crédito valga al menos el doble de lo solicitado. Independientemente de los montos solicitados los créditos pueden ser fondeados por más de un inversor en la figura de <strong>“POOL”</strong>. En esta figura todos en partes proporcionales serán acreedores en primer lugar de ese crédito específico. Clubcashin. com será el administrador único del préstamo.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row align-items-stretch">
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div>
                          <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                            03
                          </div>
                        </div>
                        <div class="font-weight-bold text-white text-left">
                          ¿Qué retorno puedo esperar?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify">
                        <li>Tasas de retorno anual: desde 10% anual (histórico marca ingresos hasta de 58.80% anual en algunos casos dependiendo de el tipo de crédito realizado).</li>
                      </ul>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div>
                          <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                            04
                          </div>
                        </div>
                        <div class="font-weight-bold text-white text-left">
                          Contamos con experiencia en el mercado financiero para ofrecer un soporte real y de riesgo controlado
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify">
                        <li>Años de experiencia: + de 5 años de experiencia en créditos prendarios sobre vehículos</li>
                        <li>Record Histórico Club Cash-In: Pérdida de capital 0% histórico</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row align-items-stretch justify-content-center">
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div>
                          <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                            05
                          </div>
                        </div>
                        <div class="font-weight-bold text-white text-left">
                          ¿Experiencia de Club Cash-In?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify">
                        <li>Club Cash-In es oficialmente la PRIMERA plataforma de créditos P2P (Peer to Peer)( de igual a igual) en el país, contando detrás de su estructura crediticia y de inversores con más de 20 años en el mercado de créditos prendarios sobre vehículos. </li>
                      </ul>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div>
                          <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                            06
                          </div>
                        </div>
                        <div class="font-weight-bold text-white text-left">
                          ¿Servicios ofrecidos por Club Cash-In?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify">
                        <li>Club Cash-In se encarga de llevar adelante desde la etapa inicial un crédito en nombre del solicitante de el mismo y de la parte inversora, iniciando por depurar a los diferentes solicitantes y sus bienes potencialmente puestos en garantía dentro de esta solicitud de financiamiento, haciendo un análisis exhaustivo de ambos tanto en las partes legales, fiscales, crediticias y valorando los bienes. Luego asesorando a ambas partes sobre como concretar el negocio de la mejor y más accesible manera, perfeccionando el mismo, llevando adelante la tarea de cobros y trámites legales durante el período que dure el crédito y si se llegará a una etapa de tener que entablar un proceso legal como sería una demanda o tomar posesión de un bien, Club Cash-In se encarga de lo mismo al 100% siempre velando de la manera más honesta y éticamente posible por ambas partes.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row align-items-stretch justify-content-center">
                    <div class="col-md-6 mb-3">
                      <div class="d-flex">
                        <div>
                          <div class="bg-primary rounded-circle text-center text-white p-2 mr-3 font-weight-bold" style="width: 50px; height: 50px; line-height: 35px">
                            07
                          </div>
                        </div>
                        <div class="font-weight-bold text-white text-left">
                          ¿Quiénes pueden invertir a través de Club Cash-In?
                        </div>
                      </div>
                      <hr class="border-light">
                      <ul class="text-justify">
                        <li>Puede hacerlo cualquier persona que desea invertir y poner a trabajar su dinero de manera inteligente con fondos de procedencia lícita y dentro de el sistema bancario. Mayor información al llenar documentación necesaria para realizar inversiones.</li>
                        <li>Cualquier persona que desee obtener altos rendimientos y que su dinero trabaje para ellos es bienvenida a pertenecer a el prestigioso Club de Inversionistas de la región “Club Cash-In”.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselPreguntasFrecuentes" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselPreguntasFrecuentes" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        <div class="row p-3 p-md-5 justify-content-center">
          <div class="col-md-12 text-center mb-3">
            <h2>Video Informativo</h2>
          </div>
          <div class="col-md-6 text-center">
            <video class="rounded img-fluid" src="https://clubcashin.com/img/CashIn_Inversionista.mp4" controls>
              Tu navegador no soporta estos videos
            </video>
          </div>
        </div>
      </div>
      <div class="row bg-primary py-5 justify-content-center" v-else>
        <div class="col-md-12">
          <div class="bg-light rounded px-2 py-2">
            <InversionistaInicio />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  import InversionistaInicio from '@/components/inicio/InversionistaInicio.vue'
  export default {
    components: {
      InversionistaInicio
    },
    data(){
      return {
        tabIndex: 0
      }
    },
    methods: {
      tabIndexClick(val){
        this.tabIndex = val
      },
    },

    computed: {
      isMobile() {
        return this.$store.getters.isMobile
      },
    }
  }
</script>